import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { PlatformService } from '@shared/services/platform.service';
import { YmService } from '@shared/services/yandex-metrica/ym.service';

/**
 * Обрабатывает событие на элементе и отправляет в Яндекс Метрику.
 * По умолчанию обрабатывается событие 'click'.
 */
@Directive({
  selector: '[ymGoal]',
  standalone: true,
})
export class YmReachGoalDirective implements AfterViewInit, OnDestroy {
  private renderer = inject(Renderer2);
  private elementRef = inject(ElementRef);
  private platform = inject(PlatformService);
  private ymService = inject(YmService);

  private unsubscribe: (() => void) | undefined;

  @Input() event = 'click';
  @Input() nameGoal = '';

  ngAfterViewInit(): void {
    if (this.platform.isServer) return;

    this.unsubscribe = this.renderer.listen(
      this.elementRef.nativeElement,
      this.event,
      () => this.ymService.reachGoal(this.nameGoal)
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) this.unsubscribe();
  }
}
