import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BurgerDatasourceService } from './services/burger.datasource.service';
import { NavItem } from './interfaces/burger.interfaces';
import { StorageService } from '@shared/core/services/storage.service';
import { VisuallyImpairedButtonComponent } from '@shared/components/visually-impaired-button/visually-impaired-button.component';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { ReportErrorModalService } from '../report-error-modal/services/report-error-modal.service';
import { WriteToHelixModalService } from '../write-to-helix-modal/services/write-to-helix-modal.service';
import { YmGoalHeader } from '@shared/modules/header/enums/ym-goal-header.enum';
import { YmReachGoalDirective } from '@shared/directives/ym-reach-goal.directive';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    NzCollapseModule,
    AsyncPipe,
    NgIf,
    VisuallyImpairedButtonComponent,
    YmReachGoalDirective,
  ],
  providers: [BurgerDatasourceService],
})
export class BurgerComponent {
  constructor(
    private datasource: BurgerDatasourceService,
    public storage: StorageService,
    private changeCityService: ChangeCityService,
    public visuallyImpaired: VisuallyImpairedService,
    public reportErrorModalService: ReportErrorModalService,
    public writeToHelixModalService: WriteToHelixModalService
  ) {}

  @Output() closeBurger = new EventEmitter<void>();

  readonly itemsBurger: NavItem[] = this.datasource.itemsBurger();
  readonly itemsCollapse: string[] = this.datasource.itemsCollapse();
  readonly itemsAdditionalInfo: NavItem[] =
    this.datasource.itemsAdditionalInfo();
  readonly itemsAboutCompany: NavItem[] = this.datasource.itemsAboutCompany();
  protected readonly YmGoalHeader = YmGoalHeader;

  openCityModal(): void {
    this.changeCityService.openCityModal();
  }

  switchOnVI(): void {
    this.visuallyImpaired.switchOnVI();
    this.emitCloseBurger();
  }

  emitCloseBurger(): void {
    this.closeBurger.emit();
  }
}
