import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  Navigation,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { PlatformService } from '@shared/services/platform.service';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class YmNavigationGoalService implements OnDestroy {
  private router = inject(Router);
  private ymService = inject(YmService);
  private platformService = inject(PlatformService);

  private ngUnsubscribe$ = new Subject<void>();

  /** Обработчик навигации для достижения цели Яндекс Метрики.
   *  Название цели передаётся через routerLink[state].
   * */
  handlerNavigation(): void {
    this.router.events
      .pipe(
        filter(() => this.platformService.isBrowser),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((event): void => {
        if (event instanceof NavigationStart) {
          history.replaceState({}, '');
        }

        if (event instanceof NavigationEnd) {
          this.reachGoal();
        }
      });
  }

  private reachGoal(): void {
    const currentNavigation: Navigation | null =
      this.router.getCurrentNavigation();

    const hasPreviousNavigation = Boolean(
      currentNavigation?.previousNavigation
    );

    // если осуществляется навигация между страницами
    if (hasPreviousNavigation) {
      const ymGoalName = currentNavigation?.extras.state?.ymGoalName;
      if (!ymGoalName) return;
      this.ymService.reachGoal(ymGoalName);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
