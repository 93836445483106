import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '@shared/core/services/storage.service';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { MetaService } from '@shared/services/meta/meta.service';
import { JsonLdStorage } from '@shared/services/meta/jsonld-schema-storage';
import { Organization } from '@shared/services/meta/schemas/organization';
import { versions } from 'src/environments/versions';
import { CityInitializerService } from '@shared/services/cityInitializer.service';
import { WebimInitializerService } from '@shared/services/initialization/webim-initializer.service';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { YmNavigationGoalService } from '@shared/services/yandex-metrica/utils/ym-navigation-goal.service';

@Component({
  selector: 'app-helix',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [WebimInitializerService, YmNavigationGoalService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'helix-version': `${versions.version}_${versions.branch}_${versions.revision}`,
  },
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    public storage: StorageService,
    private router: Router,
    private metaService: MetaService,
    private cityInitializer: CityInitializerService,
    private webimInitializerService: WebimInitializerService,
    private ymService: YmService,
    private ymGoalNavigation: YmNavigationGoalService
  ) {}

  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.ymGoalNavigation.handlerNavigation();

    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((event): void => {
        if (event instanceof NavigationEnd) {
          this.ymService.hit();
          this.metaService.setCanonical(event.url);
        }
      });

    this.storage.federalPhone$
      .pipe(
        filter((phone: string | null): phone is string => !!phone),
        first()
      )
      .subscribe((phone: string): void => {
        const schema: Organization = JsonLdStorage.Organization(phone);
        this.metaService.insertJsonLdSchema(schema);
      });

    this.cityInitializer
      .rootComponentStartPart()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this.storage.configuration$
      .pipe(first())
      .subscribe((config) => this.webimInitializerService.initWebim(config));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
