import { Injectable } from '@angular/core';
import { NavItem } from '../interfaces/burger.interfaces';
import { HelixRoutes } from '@shared/enums/routes';
import { HelixRoutePage } from '@shared/enums/helix-route-pages';

@Injectable()
export class BurgerDatasourceService {
  itemsBurger(): NavItem[] {
    return [
      { link: `/${HelixRoutes.Promotions}`, title: 'Скидки и акции', testId: 'adaptive-header-nav-promo', },
      { link: `/${HelixRoutes.Centers}`, title: 'Адреса Центров Хеликс' , testId: 'adaptive-header-nav-centers', },
      { link: `/${HelixRoutes.Helixbook}`, title: 'Helixbook', testId: 'adaptive-header-nav-kb', },
    ];
  }

  itemsCollapse(): string[] {
    return ['Сервисы', 'Еще'];
  }

  itemsAdditionalInfo(): NavItem[] {
    return [
      {
        link: HelixRoutePage.HowGetResult,
        title: 'Как получить результат',
        testId: 'header-nav-info-howtoget',
      },
      {
        link: HelixRoutePage.PreorderInfo,
        title: 'Предварительный заказ',
        testId: 'header-nav-info-preorder',
      },
      {
        link: HelixRoutePage.LkkInfo,
        title: 'О Личном кабинете',
        testId: 'header-nav-info-lkk',
      },
      {
        link: HelixRoutePage.PrepareInfo,
        title: 'Подготовка к анализам',
        testId: 'header-nav-info-prepare',
      },
      {
        link: HelixRoutePage.DmsInfo,
        title: 'Прием по ДМС',
        testId: 'header-nav-info-dms',
      },
    ];
  }

  itemsAboutCompany(): NavItem[] {
    return [
      { link: HelixRoutePage.Contacts, title: 'Контакты', testId: 'adaptive-header-nav-contacts' },
      { link: HelixRoutePage.QualityControl, title: 'Контроль качества', testId: 'adaptive-header-nav-quality-control' },
      { link: HelixRoutePage.Licenses, title: 'Лицензии и сертификаты', testId: 'adaptive-header-nav-certificates' },
      { link: `/${HelixRoutes.Publications}`, title: 'Пресса о нас', testId: 'adaptive-header-nav-press-about-us' },
      { link: `/${HelixRoutes.Job}`, title: 'Вакансии', testId: 'adaptive-header-nav-job' },
    ];
  }
}
