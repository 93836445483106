import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, map, takeUntil } from 'rxjs';

import { StorageService } from '@shared/core/services/storage.service';
import { AddCartItemService } from '@shared/modules/add-cart-item/services/add-cart-item.service';
import { HelixRoutes } from '@shared/enums/routes';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { PlatformService } from '@shared/services/platform.service';
import { YmGoalHeader } from '@shared/modules/header/enums/ym-goal-header.enum';
import { ReportErrorModalService } from '@shared/components/report-error-modal/services/report-error-modal.service';
import { WriteToHelixModalService } from '@shared/components/write-to-helix-modal/services/write-to-helix-modal.service';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';
import { HeaderRepositoryService } from '../../services/header-repository.service';
import { NavItem } from '../../types/header.types';

@Component({
  selector: 'app-helix-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    public repository: HeaderRepositoryService,
    public storage: StorageService,
    public changeCityService: ChangeCityService,
    public visuallyImpaired: VisuallyImpairedService,
    public platform: PlatformService,
    public reportErrorModalService: ReportErrorModalService,
    public writeToHelixModalService: WriteToHelixModalService,
    private router: Router,
    private addCartItemService: AddCartItemService,
    private ymService: YmService,
    private navigationMenuService: NavigationMenuService
  ) {}

  @HostListener('window:resize', ['$event'])
  checkIfShouldShowMobileSearch(): void {
    if (this.platform.isBrowser) {
      this.shouldShowMobileSearch = window?.innerWidth < this.mobileWidthPx;
    }
  }

  /** Должны ли показать версию поиска для адаптива или обычную. */
  shouldShowMobileSearch = false;

  clientNavItems$: Observable<NavItem[]> =
    this.navigationMenuService.appointmentUrl$.pipe(
      map((appointmentUrl) => [
        {
          link: '/catalog',
          name: 'Сдать анализы',
          testId: 'header-nav-catalog',
        },
        { link: '/ms', name: 'Выезд на дом', testId: 'header-nav-ms' },
        {
          link: appointmentUrl,
          name: 'Запись к врачу',
          testId: 'header-nav-practitioner-appointment',
        },
        {
          link: '/promotions',
          name: 'Скидки и акции',
          testId: 'header-nav-promo',
        },
        {
          link: '/centers',
          name: 'Адреса Центров Хеликс',
          testId: 'header-nav-centers',
        },
        { link: '/kb', name: 'Helixbook', testId: 'header-nav-kb' },
      ])
    );

  aboutCompanyNavItems: NavItem[] = [
    { link: '/site/page/107', name: 'Контакты', testId: 'header-nav-contacts' },
    {
      link: '/site/page/116',
      name: 'Контроль качества',
      testId: 'header-nav-quality-control',
    },
    {
      link: '/site/page/46',
      name: 'Лицензии и сертификаты',
      testId: 'header-nav-certificates',
    },
    {
      link: '/publishing',
      name: 'Пресса о нас',
      testId: 'header-nav-press-about-us',
    },
    { link: '/job', name: 'Вакансии', testId: 'header-nav-job' },
  ];

  additionalInfoNavItems: NavItem[] = [
    {
      link: '/site/page/86',
      name: 'Как получить результат',
      testId: 'header-nav-info-howtoget',
    },
    {
      link: '/site/page/53',
      name: 'Предварительный заказ',
      testId: 'header-nav-info-preorder',
    },
    {
      link: '/site/page/65',
      name: 'О Личном кабинете',
      testId: 'header-nav-info-lkk',
    },
    {
      link: '/site/page/59',
      name: 'Подготовка к анализам',
      testId: 'header-nav-info-prepare',
    },
    {
      link: '/site/page/52',
      name: 'Прием по ДМС',
      testId: 'header-nav-info-dms',
    },
  ];

  headerNavItems$: Observable<NavItem[]> = this.storage.configuration$.pipe(
    map(({ hosts }) => {
      return [
        {
          link: hosts.doctorAccount,
          name: 'Врачам',
          ymGoalName: YmGoalHeader.DoctorsButtonClick,
          testId: 'header-nav-for-doctors',
        },
        {
          link: hosts.b2b,
          name: 'Организациям',
          ymGoalName: YmGoalHeader.OrganizationsButtonClick,
          testId: 'header-nav-for-organizations',
        },
        {
          link: 'https://fr.helix.ru/',
          name: 'Франчайзинг',
          ymGoalName: YmGoalHeader.FranchisingButtonClick,
          testId: 'header-nav-franchising',
        },
      ];
    })
  );

  loginLink$: Observable<string> = this.storage.configuration$.pipe(
    map(({ hosts }) => hosts.login)
  );

  personalAccount$: Observable<string> = this.storage.configuration$.pipe(
    map(({ hosts }) => hosts.personalAccount)
  );

  protected readonly YmGoalHeader = YmGoalHeader;

  /** Ширина окна, меньше которой показываем мобильную версию. */
  private readonly mobileWidthPx = 576;

  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.checkIfShouldShowMobileSearch();
  }

  openCityModal(): void {
    this.changeCityService.openCityModal();
  }

  switchOnVI(): void {
    this.visuallyImpaired.switchOnVI();
  }

  updateHxidsAndGoToCart(): void {
    this.addCartItemService
      .getCartHxids()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((hxids) =>
        this.router.navigate([
          HelixRoutes.Cart,
          hxids.length ? 'step1' : 'empty',
        ])
      );
  }

  ymReachGoal(name: string | undefined): void {
    if (!name) return;
    this.ymService.reachGoal(name);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
