import { Meta, Title } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { JsonLdSchema } from './schemas/schema';
import { filter, first, map } from 'rxjs';
import { StorageService } from '@shared/core/services/storage.service';

@Injectable()
export class MetaService {
  private readonly DEFAULT_META_KEYWORDS =
    'Хеликс, Лабораторная, служба, анализы, срочные анализы, медицинская, лабораторная, диагностика, общеклинические, биохимические, цитологические, микробиологические, генетические';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private storage: StorageService
  ) {}

  title(title: string): void {
    this.titleService.setTitle(title);
    this.setMeta('property', 'og:title', title);
  }

  description(content: string): void {
    this.setMeta('name', 'description', content);
    this.setMeta('property', 'og:description', content);
  }

  keywords(content: string): void {
    this.setMeta(
      'name',
      'keywords',
      [this.DEFAULT_META_KEYWORDS, content].join(', ')
    );
  }

  /**
   * Устанавливает мета-тег подтверждения прав на управление сайтом
   * (только для главной страницы, см. https://yandex.ru/dev/webmaster/doc/dg/concepts/verification.html).
   * @param token Код подтверждения.
   */
  yandexVerification(token: string): void {
    this.setMeta('name', 'yandex-verification', token);
  }

  insertJsonLdSchema(schema: JsonLdSchema): void {
    let script: HTMLScriptElement;
    let shouldAppend = false;
    const currentSchemas = this._getJsonLdSchema().filter(
      (x) => x['@type'] !== schema['@type']
    );
    const newSchemas = [...currentSchemas, schema];

    const scriptClassName = 'structured-data';
    if (this.document.head.getElementsByClassName('structured-data').length) {
      script = this.document.head.getElementsByClassName(
        scriptClassName
      )[0] as HTMLScriptElement;
    } else {
      script = this.document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', scriptClassName);
    script.type = 'application/ld+json';
    script.text = JSON.stringify(newSchemas);
    if (shouldAppend) {
      this.document.head.appendChild(script);
    }
  }

  setCanonical(relativeUrl: string): void {
    this.storage.configuration$
      .pipe(
        filter((config) => !!config),
        first(),
        map((config) => config.hosts.main)
      )
      .subscribe((mainUrl) => {
        const url = this._createUrl(mainUrl, relativeUrl);
        this._setCanonicalUrl(url);
      });
  }

  private setMeta(
    propertyName: 'name' | 'property',
    propertyValue: string,
    content: string
  ) {
    const meta = new Meta(this.document);
    const tag = { content };
    Object.defineProperty(tag, propertyName, {
      value: propertyValue,
      enumerable: true,
    });
    const selector = `${propertyName}="${propertyValue}"`;

    meta.updateTag(tag, selector);
  }

  private _createUrl(host: string, relativeUrl: string): string {
    // remove slash
    host = host.replace(/(\/)?$/g, '');
    // remove query params and slash
    relativeUrl = relativeUrl.split('?')[0].replace(/(\/)?$/g, '');
    return `${host}${relativeUrl}`;
  }

  private _setCanonicalUrl(url: string): void {
    const head = this.document.head;
    let element = this.document.querySelector(`link[rel='canonical']`) || null;
    if (!element) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.setMeta('property', 'og:url', url);
  }

  private _getJsonLdSchema(): JsonLdSchema[] {
    const script = this.document.head.getElementsByClassName(
      'structured-data'
    )[0] as HTMLScriptElement;

    if (!script) return [];

    const schemas = JSON.parse(script.innerHTML);

    return schemas;
  }
}
