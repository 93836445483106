import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CatalogSearchResult,
  PreorderAvailabilityStatus,
} from '@medindex-webapi';
import { WarningModalComponent } from '@shared/components/warning-modal/warning-modal.component';
import { ModalErrorType } from '@shared/enums/modal-error-type';
import { ModalSize } from '@shared/enums/modal-sizes';
import { AddToCartMessages } from '@shared/modules/add-cart-item/const/messages';
import { AddCartItemService } from '@shared/modules/add-cart-item/services/add-cart-item.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { first, take } from 'rxjs';
import { TabContent } from '@shared/components/searcher/types/tab-content';
import { ModalTitle } from '@shared/enums/modal-title.enum';
import { ModalAppointmentDoctorComponent } from '@shared/modules/modal-appointment-doctor/modal-appointment-doctor.component';
import { IServiceInfo } from '@shared/modules/modal-appointment-doctor/interfaces/modal-appointment-doctor.interfaces';
import { StorageService } from '@shared/core/services/storage.service';
import { CommonModule } from '@angular/common';
import { SearcherResultCountComponent } from '../searcher-result-count/searcher-result-count.component';
import { RouterLink } from '@angular/router';
import { HelixRoutes } from '@shared/enums/routes';
import { YmReachGoalDirective } from '@shared/directives/ym-reach-goal.directive';
import { YmGoalSearcher } from '@shared/components/searcher/enums/ym-goal-searcher.enum';
import { YmGoalCatalogItem } from '../../../../../../../modules/catalog/enums/ym-goal-catalog-item.enum';

@Component({
  selector: 'searcher-result-catalog',
  templateUrl: './searcher-result-catalog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SearcherResultCountComponent,
    YmReachGoalDirective,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherResultCatalogComponent {
  constructor(
    public addCartItemService: AddCartItemService,
    private modalService: ModalService,
    private storage: StorageService
  ) {}

  @Input() data: TabContent = [];
  @Output() closeModal = new EventEmitter<void>();

  protected readonly HelixRoutes = HelixRoutes;
  protected readonly YmGoalSearcher = YmGoalSearcher;
  protected readonly YmGoalCatalogItem = YmGoalCatalogItem;

  openCannotPreOrderModal(item: CatalogSearchResult): void {
    if (item.preorderAvailability === PreorderAvailabilityStatus.Appointment) {
      this.storage.cityName$.pipe(first()).subscribe((cityName) => {
        this._openModalAppointmentDoctor({
          hxid: item.hxid ?? '',
          price: item.price.toString(),
          currency: item.currency ?? '',
          description: item.description ?? '',
          cityName: cityName,
        });
      });
    } else {
      this.modalService.create({
        nzContent: WarningModalComponent,
        nzComponentParams: {
          content: AddToCartMessages.CannotPreorderMessage,
          errorType: ModalErrorType.Info,
        },
        nzStyle: { width: ModalSize.Info },
        nzFooter: null,
      });
    }
  }

  private _openModalAppointmentDoctor(serviceInfo: IServiceInfo): void {
    this.modalService.create({
      nzTitle: ModalTitle.MakeAppointment,
      nzContent: ModalAppointmentDoctorComponent,
      nzStyle: {
        width: ModalSize.Form,
      },
      nzClassName: 'modal-with-bg',
      nzComponentParams: {
        serviceInfo,
      },
      nzFooter: null,
    });
  }

  addToCart(hxid: string): void {
    this.addCartItemService
      .addItemWithConfirmation(hxid)
      .pipe(take(1))
      .subscribe();
  }

  catalog(): CatalogSearchResult[] {
    return this.data as CatalogSearchResult[];
  }

  closeResults(): void {
    this.closeModal.emit();
  }
}
